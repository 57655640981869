import { render, staticRenderFns } from "./UserEdit.vue?vue&type=template&id=45e21355&scoped=true"
import script from "./UserEdit.vue?vue&type=script&lang=js"
export * from "./UserEdit.vue?vue&type=script&lang=js"
import style0 from "./UserEdit.vue?vue&type=style&index=0&id=45e21355&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45e21355",
  null
  
)

export default component.exports