<template>
<d-card>
    <div class="lineCharts">
        <h5>{{ title }}</h5>
        <apexchart :height="height" width="100%" type="bar" :options="chartOptions" :series="series"></apexchart>
    </div>
</d-card>
</template>

<script>
import { RepositoryAPI } from '@/api/repository_api';
const callApi  = RepositoryAPI.get('auth_admin');

export default {
    name: 'LineChartsData',
    props: {
        title: {
            type: String,
            default: ''
        },
        height: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            chartOptions: {
                chart: {
                    id: 'cart-transaksi',
                    width: "100%",
                    height: 300,
                },
                xaxis: {
                    type: "category", 
                    categories: []
                }
            },
            series: [{
                name: 'Transaksi',
                data: []
            }],
            legend: {
                position: "right",
                verticalAlign: "top",
                containerMargin: {
                    left: 35,
                    right: 60
                }
            },
            responsive: [
                {
                breakpoint: 1000,
                options: {
                    plotOptions: {
                    bar: {
                        horizontal: false
                    }
                    },
                    legend: {
                        position: "bottom"
                    }
                }
                }
            ],
        };
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            try {
                const res = await callApi.grafikTransaksi();
                const month = res.data.data.map(item => item.bulan);
                const valMonth = res.data.data.map(item => item.jumlahTransaksi);

                console.log("Months Array:", month); // Debugging Step

                this.$set(this.chartOptions.xaxis, 'categories', month);
                this.$set(this.series[0], 'data', valMonth);

                // Ensure labels don't throw an error
                this.chartOptions = {
                    ...this.chartOptions,
                    xaxis: {
                        type: "category",
                        categories: month,
                        labels: {
                            formatter: (val) => (val !== undefined ? val.toString() : '') 
                        }
                    }
                };
            } catch(e) {
                console.error(e);
            }
        }

    }
}
</script>
<style lang="scss" scoped>
.lineCharts {
    padding: 15px;
}
</style>
